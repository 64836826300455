import './unauthorized.scss'
import Button from '../../components/button/button';
import { Link } from 'react-router-dom';

const Unauthorized = () => {
    return (
        <div className="unauthorized-container">
            <div>
            <h2>You are not authorized to view this page.</h2>
            <Button type="button" additionalClass="linked inverted"><Link to='/'>Return to Homepage</Link></Button>
            </div>
        </div>
    )
}

export default Unauthorized;