import './document-item.scss'
import { format } from "date-fns";
import Button from '../button/button';
import {useNavigate} from 'react-router-dom';

const DocumentItem = ({doc, office, current, index}) => {
    const navigate = useNavigate();

    const { title, type, created, particulars, pr } = doc;

    const onClickHandler = () => {
        navigate('/tracking/' + doc.id);
    }
    return (
            <tr>
                <td>{index + 1}.</td>
                <td>{title}{particulars ? <p className='particulars'><small>{particulars}</small></p> : ''}{pr ? <p>PR: {pr}</p> : ''}</td>
                <td>{type}</td>
                <td>{format(created.toDate(), "MMM dd, yyyy ~ h:mma")}</td>
                <td>{office.name}</td>
                <td>{current}</td>
                <td><Button type="button" onClick={onClickHandler} buttonType="inverted">View</Button></td>
            </tr>
    )
}

export default DocumentItem;