import './button.scss'

const BUTTON_TYPES = {
    primary: 'primary',
    inverted: 'inverted'
}

const Button = ({ children, buttonType, additionalClass, ...otherProps }) => {
    return <button className={`button-container ${buttonType ? BUTTON_TYPES[buttonType] : ""} ${additionalClass ? additionalClass : ""}`} {...otherProps}>{children}</button>
}

export default Button;